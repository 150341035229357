import { SocialUser } from '@abacritt/angularx-social-login';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import jwt_decode from 'jwt-decode';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EstaLogueadoGuard implements CanActivate {
  logueado: Boolean = false;

  constructor(private router: Router, private _login: AuthService) {
    this.logueado = true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const tipoLogin =  localStorage.getItem('tipoLogin')

    if (token && tipoLogin && user) {
      const decodedToken = jwt_decode(token) as { [key: string]: any };
      const expirationDate = new Date(decodedToken['exp'] * 1000);
      const currentDate = new Date();
      if (expirationDate < currentDate) {
        // Token expirado, redirigir al usuario al login   
        localStorage.clear()
        return true;
      }
      else
      {
        this.router.navigate(['/convivenciaEscolar/casos']);
        return false
      }      
    }
    return true;
  }
}
