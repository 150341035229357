<!DOCTYPE html>
<html lang="es" class="notranslate" translate="no">
<head>
    <meta name="google" content="notranslate" charset="utf-8"/>
</head>

<body>

    <!--Header----------------->

    <!--Contenedor-------------->
    <div [ngClass]="{'no-margin': !ltt, 'sm-container': ltt }">
        <div class="row h-100">
            <div class="col-3 sidemenu" [ngClass]="{'oculto': !ltt, '': ltt }">
                <app-navbar></app-navbar>
            </div>
            <div [ngClass]="{'col-12': !ltt, 'col-9 sidecontenido': ltt }">
                <app-header [ngClass]="{'oculto': !ltt, '': ltt }"></app-header>
                <div [ngClass]="{'oculto': !ltt, 'separadorheader': ltt }"></div>
                <div class="h-100">

                    <router-outlet></router-outlet>
                </div>

            </div>

        </div>

    </div>
</body>
<footer [ngClass]="{'oculto': !ltt, 'sm-footer': ltt }">
    <label id="footerNombreColegio"></label>
</footer>

<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="cube-transition" [fullScreen]="true">
    <p style="color: white"> Carganding </p>
</ngx-spinner> -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Cargando</p>
</ngx-spinner>