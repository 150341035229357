import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly url = environment.globalUrl;

  constructor(private http: HttpClient) {}

  public login(values: any): Observable<any> {
    
    let params = {
      rut: values.rut,
      password: values.password      
    };
    return this.http.post(this.url + 'auth/login', params);
  }

  logout(): void {
    localStorage.clear();
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  public loginGoogle(values: any): Observable<any> {
    return this.http.post(this.url + 'auth/loginGoogle', values);
  }

  public aniosEstablecimiento(baseDatos:string): Observable<any> {    
    return this.http.get(this.url + `convivenciaEscolar/getDatos/anioColegio/${baseDatos}`);
  }


  public cambiarClave(values:any): Observable<any> {    
    return this.http.post(this.url + 'auth/cambiarClave', values);    
  }

  public isAdmin(data: any) {
    return this.http.get(this.url + `auth/isAdmin/${data.id_usuario}/${data.baseDatos}/${data.id_ano}`);
  }

  

}
