<header class="sm-header">
    <div class="row hr-row">
        <div class="col-6">
            <a href="#" class="btn-header"><i class="fas fa-ellipsis-v"></i></a>
            <h1 class="tituloheader">{{valor}}</h1>
        </div>
        <div class="col-3" style="display: flex; justify-content: flex-end;">
            <button *ngIf="tokenExist" class="sm-btn sm-btn-secondary" (click)="cerrarSesion()">
                Cerrar Sesión
            </button>
        </div>
        <div class="col-3"></div>
    </div>
</header>