import { Component } from '@angular/core';
import { TitleService } from './services/title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [TitleService],
})
export class AppComponent {
  ltt: boolean = false;
  nombreColegio: string = ''

  constructor(private _title: TitleService) {

    this._title.getRouteChange().subscribe((event) => {
      if (event.urlAfterRedirects != '/login') {
        
        if (localStorage.getItem('token')) {
          this.ltt = true;
        } else {
          this.ltt = false;
        }
      } else {
        this.ltt = false;
      }
    });
  }
}
