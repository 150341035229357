import { SocialUser } from '@abacritt/angularx-social-login';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import jwt_decode from 'jwt-decode';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TokenRevGuard implements CanActivate {
  logueado: Boolean = false;
  user: SocialUser = new SocialUser();
  constructor(private router: Router, private _login: AuthService) {
    this.logueado = true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const token = localStorage.getItem('token');
    let user: any;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user') ?? '');
    }
    
    if (token && localStorage.getItem('tipoLogin') == 'directo') {
      const decodedToken = jwt_decode(token) as { [key: string]: any };
      const expirationDate = new Date(decodedToken['exp'] * 1000);
      const currentDate = new Date();
      if (expirationDate < currentDate) {
        // Token expirado, redirigir al usuario al login
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    } 
    else if (localStorage.getItem('tipoLogin') == 'google' && user &&  localStorage.getItem('token') ) {
      return true;
    } 
    else
    {
      console.log('1');
      // No hay token, redirigir al usuario al login
      this.router.navigate(['/login']);
      return false;
    }
  }
}
