import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpStatusCode,
  HttpHeaders
} from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EMPTY } from 'rxjs';
import * as CryptoJS from 'crypto-js';


@Injectable()
export class AgregarTokenInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private router : Router,
    private authService: SocialAuthService,
    private _spinner: NgxSpinnerService,
    private _ToastrService: ToastrService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let user: any = "";
    let permisos: any = "";
    let usuario: any = "";
    let adm: any = "";
    let transactionID: any = "";
    let suma = 0
    
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user')?? "") ?? "";
      permisos = localStorage.getItem('permisos') ?? "";
      adm = localStorage.getItem('isAdmin') ?? "";
      usuario = user.rut+permisos

      for (let i = 0; i < usuario.length; i++) {
        const caracter = parseInt(usuario[i]);
    
        if (!isNaN(caracter)) {
          // Verifica si el carácter es un número
          suma += caracter;
        }
      }
    }
    transactionID = CryptoJS.SHA1(suma+adm).toString(CryptoJS.enc.Hex);
  
    // const headers = new HttpHeaders().set('transactionID', transactionID);
    const newRequest  = request.clone({
      // headers: headers,
      setHeaders: {
        Authorization: `bearer ${localStorage.getItem('token')}`
      },
    });

    return next.handle(newRequest).pipe(
      map((event: HttpEvent<any>) => {
          return event;
      }),
      catchError(
        (httpErrorResponse: HttpErrorResponse, _: Observable<HttpEvent<any>>) => {
          if (httpErrorResponse.status === HttpStatusCode.Unauthorized) {
              setTimeout(() => {
                if (localStorage.getItem('tipoLogin') == 'google') {
                  this.authService.signOut();
                }
                if(localStorage.getItem('token')){
                  localStorage.clear();
                  this._spinner.hide();
                  this.router.navigate(['login']);
                  this._ToastrService.warning('token invalido');
                }
                else{
                  localStorage.clear();
                  this._spinner.hide();
                  this.router.navigate(['login']);
                }
                
              }, 200);  
          }
          return throwError(httpErrorResponse); 
        }
      )
    );
  }
}