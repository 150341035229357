import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TokenRevGuard } from './guards/token-rev.guard';
import { EstaLogueadoGuard } from './guards/estaLogeando.guard';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'convivenciaEscolar',
    loadChildren: () =>
      import(
        './Components/convivencia-escolar/convivencia-escolar.module'
      ).then((m) => m.ConvivenciaEscolarModule),
    canActivate: [TokenRevGuard],
    data: {
      redirectTo: '/login',
    },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./Components/log-in/log-in.module').then((m) => m.LogInModule),
    canActivate: [EstaLogueadoGuard],
  },
  {
    path: 'casoconvivencia',
    loadChildren: () =>
      import(
        './Components/caso-convivencia-escolar/caso-convivencia-escolar.module'
      ).then((m) => m.CasoConvivenciaEscolarModule),
    canActivate: [TokenRevGuard],
    data: {
      redirectTo: '/login',
    },
  },
  {
    path: 'matriculaescolar',
    loadChildren: () =>
      import('./Components/matricula-escolar/matricula-escolar.module').then(
        (m) => m.MatriculaEscolarModule
      ),
    canActivate: [TokenRevGuard],
    data: {
      redirectTo: '/login',
    },
  },
  {
    path: 'curso',
    loadChildren: () =>
      import('./Components/cursos/curso.module').then((m) => m.CursoModule),
    canActivate: [TokenRevGuard],
    data: {
      redirectTo: '/login',
    },
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [TokenRevGuard],
    data: {
      redirectTo: '/login',
    },
  },
  {
    path: 'matricula',
    loadChildren: () =>
      import(
        './Components/matricula-escolar/matricula-escolar.module'
      ).then((m) => m.MatriculaEscolarModule),
    canActivate: [TokenRevGuard],
    data: {
      redirectTo: '/login',
    },
  },
  { path: '**', redirectTo: '/convivenciaEscolar/casos' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
