import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private routeChange: Observable<NavigationEnd>;

  constructor(private router: Router) {
    this.routeChange = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  getRouteChange(): Observable<NavigationEnd> {
    return this.routeChange;
  }
}
