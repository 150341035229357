import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TitleService } from 'src/app/services/title.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [TitleService],
})
export class HeaderComponent implements OnInit {
  valor: string = '';
  tokenExist: boolean = false;
  idcaso: any;

  constructor(
    private _title: TitleService,
    private router: Router,
    private _spinner: NgxSpinnerService,
    private authService: SocialAuthService
  ) {}

  ngOnInit() {

    this._title.getRouteChange().subscribe((event) => {
      if (localStorage.getItem('token')) {
        this.tokenExist = true;
      }
      if (!localStorage.getItem('token')) {
        this.tokenExist = false;
      }

      if (event.url.includes('pasos')) {
        this.valor = 'Mantenedor de pasos';
        return
      }
      if (event.url.includes('ficha-alumno')) {
        this.valor = 'Ficha del alumno';
      }
      if (event.url.includes('protocolos')) {
        this.valor = 'Mantenedor de protocolos';
        return
      }
      if (event.url.includes('caso')) {
        let infocaso = localStorage.getItem('idCaso');
        if (infocaso) {
          console.log('infocaso', infocaso);
          if (infocaso!=null) {
            this.idcaso = JSON.parse(infocaso);
            this.valor = 'Caso ' + this.idcaso.id + ' - ' + this.idcaso.protocolo;
          }
          return 
        }
        else{
          this.idcaso = ''
          this.valor = 'Mantenedor de Casos';
          return
        }
        
        
      }

      if (event.url.includes('convivenciaEscolar/casos')) {
        this.valor = 'Casos de convivencia escolar';
        return
      }
      if (event.url.includes('caso-crear')) {
        this.valor = 'Nuevo caso';
        return
      }
      // if (event.url.includes("protocolos")) {
      //   this.valor = 'Mantenedor de protocolos';
      // }
      if (event.url.includes('dashboard')) {
        this.valor = 'Reportes';
        return
      }
      if (event.url.includes('cursosListado')) {
        this.valor = 'Listado de Cursos';
        return 
      }
      if (event.url.includes('cursos/-1')) {
        this.valor = 'Nuevo Curso';
        return 
      }
      if (event.url.includes('cursos/')) {
        const a = event.url.split('/')        
        this.valor = `Actualizar Curso "${localStorage.getItem('nombreCurso')}"`;
        return 
      }
      if (event.url.includes('alumnosMatriculados')) {
        const a = event.url.split('/')        
        this.valor = 'Lista de Alumnos';
        return 
      }
      if (event.url.includes('home')) {
        this.valor = '';
        return
      }
      if (event.url.includes('encargado')) {
        this.valor = 'Encargados de convivencia';        
        return
      }
      if (event.url.includes('nuevaMatricula')) {
        this.valor = 'Nueva Matricula';        
        return
      }
      if (event.url.includes('/matricula/alumnos')) {
        this.valor = 'Movimientos Alumno';        
        return
      }
    });
  }

  cerrarSesion() {
    this._spinner.show();
    setTimeout(() => {
      if (localStorage.getItem('tipoLogin') == 'google') {
        this.authService.signOut();
      }
      localStorage.clear();
      this._spinner.hide();
      this.router.navigate(['login']);
    }, 1000);
  }
}
