import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PermisosService } from 'src/app/services/permisos.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  tokenExist: any = false;
  user: any;
  tipo: any;
  fotoUsuario: string = '';
  logo: string = '';
  nombreColegio: any = '';

  permisos={
    mantenedorPasos: false,
    mantenedorProtocolos:false,
    casosConvivencia:false,
    encargadosConvivencia:false,
    mantenedorCursos:false,
    listadoAlumnosMatriculados:false,
    fichaAlumno:false
  }

  constructor(private _router: Router, private _title: TitleService, private permisoServicio: PermisosService ) {
    
    const estilo = localStorage.getItem('estiloCss');
    if (estilo) {
      $('#estiloCss').attr('href', estilo);
    }

    setTimeout(() => {
      $('#footerNombreColegio').text(`${localStorage.getItem('nombreColegio')} ${localStorage.getItem('numeroAnio')}`);  
    }, 2500);
    

    this._title.getRouteChange().subscribe((event) => {
      if (
        localStorage.getItem('token') &&
        localStorage.getItem('tipoLogin') == 'google'
      ) {
        this.tokenExist = true;
        this.tipo = 'google';
        this.user = JSON.parse(localStorage.getItem('user') ?? '');
        this.fotoUsuario = this.user.foto;
        this.logo = `assets/img/${localStorage.getItem('logoColegio')}`;
        const anio = new Date().getFullYear();
        console.log(anio);
        this.nombreColegio = `${localStorage.getItem(
          'nombreColegio'
        )} ${localStorage.getItem('numeroAnio')}`;
      }
      if (
        localStorage.getItem('token') &&
        localStorage.getItem('tipoLogin') == 'directo'
      ) {
        this.tokenExist = true;
        this.tipo = 'directo';
        this.user = JSON.parse(localStorage.getItem('user') ?? '');
        this.fotoUsuario = this.user.foto;
        this.logo = `assets/img/${localStorage.getItem('logoColegio')}`;
        this.nombreColegio = `${localStorage.getItem(
          'nombreColegio'
        )} ${localStorage.getItem('numeroAnio')}`;
      }
      if (!localStorage.getItem('token')) {
        this.tokenExist = false;
      }

      if (localStorage.getItem('permisos')) {     
        this.eliminarOpcionesMenu()        
      }

    });
  }

  irA(param: any) {

    switch (param) {
      case 'mantenedorP':
        this._router.navigate(['/convivenciaEscolar/pasos']);
        break;
      case 'mantenedorProtocolos':
        this._router.navigate(['/convivenciaEscolar/protocolos']);
        break;
      case 'mantenedorCasos':
        this._router.navigate(['/convivenciaEscolar/casos']);
        break;
      case 'mantenedorEncargados':
        this._router.navigate(['/convivenciaEscolar/encargados']);
        break;
      case 'permisosAno':
        this._router.navigate(['/convivenciaEscolar/permisos']);
        break;
      case 'dashboard':
        this._router.navigate(['/convivenciaEscolar/dashboard']);
        break;
      case 'listadoCurso':
        this._router.navigate(['/matriculaescolar/cursosListado']);
        break;
      case 'listadoAlumnosMatriculados':
        this._router.navigate(['/matriculaescolar/alumnosMatriculados']);
        break;
      default:
        this._router.navigate(['/']);
        break;
    }
  }

  eliminarOpcionesMenu(){
    const permisos = localStorage.getItem('permisos')
    this.permisos.mantenedorProtocolos= this.permisoServicio.tienePermisoVer('1701',permisos)
    this.permisos.mantenedorPasos= this.permisoServicio.tienePermisoVer('1702',permisos)
    this.permisos.casosConvivencia= this.permisoServicio.tienePermisoVer('1703',permisos)
    this.permisos.encargadosConvivencia= this.permisoServicio.tienePermisoVer('1704',permisos)
    this.permisos.mantenedorCursos= this.permisoServicio.tienePermisoVer('49',permisos)
    this.permisos.listadoAlumnosMatriculados= this.permisoServicio.tienePermisoVer('411',permisos)
    this.permisos.fichaAlumno= this.permisoServicio.tienePermisoVer('408',permisos)
    
  }

  mostrarMenu(nombreModulo:string){

    switch(nombreModulo){
      case 'convivencia':
        return this.permisos.mantenedorProtocolos || this.permisos.mantenedorPasos ||
               this.permisos.casosConvivencia || this.permisos.encargadosConvivencia

      case 'cursos':
        return this.permisos.mantenedorCursos || this.permisos.listadoAlumnosMatriculados ||
               this.permisos.fichaAlumno
        
      default:
        return false
    }
  }
}
