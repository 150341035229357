import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermisosService {

  urlModulos= [
    {nombre: '/convivenciaEscolar/casos', modulo:'1703'},
    {nombre: '/casoconvivencia/caso', modulo:'1703'},
    {nombre: '/convivenciaEscolar/pasos', modulo:'1702'},
    {nombre: '/convivenciaEscolar/protocolos', modulo:'1701'},
    {nombre: '/convivenciaEscolar/encargados', modulo:'1704'},    
    {nombre: '/matriculaescolar/cursosListado', modulo:'49'},   
    {nombre: '/matriculaescolar/cursos', modulo:'49'},   
    {nombre: '/matriculaescolar/alumnosMatriculados', modulo:'411'},        
    {nombre: '/matriculaescolar/nuevaMatricula', modulo:'445'},   
    {nombre: '/matricula/alumnos', modulo:'447'},   
    {nombre: '/curso/ficha-alumno', modulo:'408'},   
    
  ]
  
  constructor() { }

  consultarIDModulo(url:any) {   
    const parametro = parseInt(url.split('/').pop()!.toString(),10)       
    // Elimino el ultimo parametro si es numerico
    if (parametro) {      
      url=url.split('/')      
      url.pop()      
      url= url.join('/')      
    }

    return this.urlModulos.filter((regs:any)=> regs.nombre.includes(url))[0]?.modulo
  }

  tienePermisoVer(modulo:any, permisos:any) {

    const auxPermisos = permisos    
    ?.replace('-', '')
    .replaceAll(/"/g, '')
    .slice(0, -1)
    .split(';-');

    let auxPermiso:any= auxPermisos?.filter((element) => element.split('-').shift()! == modulo);           
    if (auxPermiso?.length==0){      
      return false
    }

    auxPermiso= auxPermiso[0]
    auxPermiso = auxPermiso?.split('-').pop()    
    const permisoLeer = auxPermiso?.substring(auxPermiso?.length-1)
    if (permisoLeer=='1'){      
      return true
    }
    else{
      return false
    }
  }
  
}
