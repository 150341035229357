<div class="row">
    <div class="separador20"></div>
    <div class="col-4"> </div>
        <div class="col-2">
            <img src="assets/img/logo-cam.png" class="logo-cam" alt="Logo Colegio Alma Mater" />
        </div>
        <div class="col-2">
            <img src="assets/img/itsm-logo.png" class="itsm-logo" alt="Logo Instituto San Mateo" />
        </div>
        <div class="col-2">
            <img src="assets/img/logo-lph.png" class="logo-lph" alt="Logo Liceo Hannover" />
        </div>
    <div class="col-4"></div>
    <div class="separador20"></div>
    <div class="separador20"></div>
    <div class="separador20"></div>
</div>



    