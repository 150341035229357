<!--Menú principal--------------->
<div class="col-3 sidemenu">
    <nav class="sm-menu">
        <div class="logo-colegio">
            <img [src]="this.logo" alt="Logo" />
            <span>{{this.nombreColegio}}</span>
        </div>
        <ul class="menu">
            <!--Usuario-------------->
            <li class="usuario">
                <input type="checkbox" id="grupo_00">
                <label class="parent" for="grupo_00">
                    <div *ngIf="tokenExist && fotoUsuario">
                        <figure class="fotoperfil"><img [src]="fotoUsuario" alt="fotoperfil" /></figure>
                    </div>
                    <div *ngIf="tokenExist &&  !fotoUsuario">
                        <figure class="fotoperfil"><img src="assets/img/no-image-user.png" alt="fotoperfil" /></figure>
                    </div>
                    <span *ngIf="tokenExist">{{user.nombres}} {{user.a_paterno}} {{user.a_materno}}</span>
                </label>
            </li>


            <!--Inicio-------------->
            <li *ngIf="mostrarMenu('convivencia')">
                <input type="checkbox" id="grupo_1">
                <label class="parent" for="grupo_1">
                    <figure class="icon"><i class="fas fa-home"></i></figure>
                    <span>Convivencia Escolar</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li *ngIf="this.permisos.mantenedorPasos"><a class="link" (click)="irA('mantenedorP')"><span>Mantenedor de pasos</span></a></li>
                    <li *ngIf="this.permisos.mantenedorProtocolos"><a class="link" (click)="irA('mantenedorProtocolos')"><span>Mantenedor de protocolos</span></a>
                    </li>
                    <li *ngIf=" this.permisos.casosConvivencia"><a class="link" (click)="irA('mantenedorCasos')"><span>Casos de Convivencia Escolar</span></a>
                    <li *ngIf=" this.permisos.encargadosConvivencia"><a class="link" (click)="irA('mantenedorEncargados')"><span>Encargados de convivencia</span></a>
                   <!--<li><a class="link" (click)="irA('permisosAno')"><span>Permisos Año</span></a>-->
                    <li><a class="link" (click)="irA('dashboard')"><span>Reportes</span></a></li>
                </ul>
            </li>


            <!--Registro de Cursos-------------->
            <li *ngIf="mostrarMenu('cursos')">
                <input type="checkbox" id="grupo_3">
                <label class="parent" for="grupo_3">
                    <figure class="icon"><i class="fas fa-child"></i></figure>
                    <span>Matrícula Escolar</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li *ngIf="this.permisos.mantenedorCursos"><a class="link" (click)="irA('listadoCurso')"><span>Mantenedor Cursos</span></a></li>

                    <li *ngIf="this.permisos.listadoAlumnosMatriculados"><a class="link" (click)="irA('listadoAlumnosMatriculados')"><span>Listado Alumnos</span></a></li>
                </ul>
            </li>
            <!--
            <li>
                <input type="checkbox" id="grupo_3">
                <label class="parent" for="grupo_3">
                    <figure class="icon"><i class="fas fa-user"></i></figure>
                    <span>Alumno</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link" (click)="irA('listadoCurso')"><span>Mantenedor</span></a></li>                    
                </ul>
            </li>
            -->


            <!--Recursos humanos-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_5">
                <label class="parent" for="grupo_5">
                    <figure class="icon"><i class="fas fa-users"></i></figure>
                    <span>Recursos Humanos</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_6">
                        <label class="parent" for="grupo_6">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->



            <!--Comunicaciones-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_7">
                <label class="parent" for="grupo_7">
                    <figure class="icon"><i class="fas fa-volume-up"></i></figure>
                    <span>Comunicaciones</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_8">
                        <label class="parent" for="grupo_8">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->


            <!--Centro de Rec. de Aprendizaje-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_9">
                <label class="parent" for="grupo_9">
                    <figure class="icon"><i class="fas fa-book-reader"></i></figure>
                    <span>Centro de Rec. de Aprendizaje</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_10">
                        <label class="parent" for="grupo_10">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->


            <!--Documentación Colaborativa-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_11">
                <label class="parent" for="grupo_11">
                    <figure class="icon"><i class="fas fa-file-alt"></i></figure>
                    <span>Documentación Colaborativa</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_12">
                        <label class="parent" for="grupo_12">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->



            <!--Evaluaciones digitales-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_13">
                <label class="parent" for="grupo_13">
                    <figure class="icon"><i class="fas fa-check-circle"></i></figure>
                    <span>Evaluaciones Digitales</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_14">
                        <label class="parent" for="grupo_14">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->


            <!--Configuración Año Escolar-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_15">
                <label class="parent" for="grupo_15">
                    <figure class="icon"><i class="fas fa-calendar-alt"></i></figure>
                    <span>Configuración Año Escolar</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_16">
                        <label class="parent" for="grupo_16">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->


            <!--Contabilidad Financiera-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_17">
                <label class="parent" for="grupo_17">
                    <figure class="icon"><i class="fas fa-donate"></i></figure>
                    <span>Contabilidad Financiera</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_18">
                        <label class="parent" for="grupo_18">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->



            <!--Inventario de Activos-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_19">
                <label class="parent" for="grupo_19">
                    <figure class="icon"><i class="fas fa-clipboard-list"></i></figure>
                    <span>Inventario de Activos</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_20">
                        <label class="parent" for="grupo_20">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->


            <!--Sostenedor-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_21">
                <label class="parent" for="grupo_21">
                    <figure class="icon"><i class="fas fa-user-tie"></i></figure>
                    <span>Sostenedor</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_22">
                        <label class="parent" for="grupo_22">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->



            <!--Portal del Alumno-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_23">
                <label class="parent" for="grupo_23">
                    <figure class="icon"><i class="fas fa-user-edit"></i></figure>
                    <span>Portal del Alumno</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_24">
                        <label class="parent" for="grupo_24">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->


            <!--Portal del Trabajador-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_25">
                <label class="parent" for="grupo_25">
                    <figure class="icon"><i class="fas fa-user-cog"></i></figure>
                    <span>Portal del Trabajador</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_26">
                        <label class="parent" for="grupo_26">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>     -->


            <!--Aula Virtual-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_27">
                <label class="parent" for="grupo_27">
                    <figure class="icon"><i class="fas fa-chalkboard-teacher"></i></figure>
                    <span>Aula Virtual</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_28">
                        <label class="parent" for="grupo_28">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->



            <!--Costos e Inventario-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_29">
                <label class="parent" for="grupo_29">
                    <figure class="icon"><i class="fas fa-money-bill-wave"></i></figure>
                    <span>Costos e Inventario</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_30">
                        <label class="parent" for="grupo_30">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->


            <!--Administración del sistema-------------->
            <!-- <li>
                <input type="checkbox" id="grupo_43">
                <label class="parent" for="grupo_43">
                    <figure class="icon"><i class="fas fa-cogs"></i></figure>
                    <span>Administración del sistema</span>
                    <figure class="arrow"><i class="fas fa-caret-down"></i></figure>
                </label>
                <ul class="child">
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                    <li>
                        <input type="checkbox" id="grupo_44">
                        <label class="parent" for="grupo_44">
                        <figure><i></i></figure>
                        <span>Parent sub 2</span>
                        <i class="fas fa-plus"></i>
                        </label>
                        <ul class="child">
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                            <li><a class="link"><span>Lorem imsum dolor sit</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>         -->


        </ul>
    </nav>
</div>